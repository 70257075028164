import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6fd451e9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "top"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "bar"
};
const _hoisted_5 = {
  class: "bar-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.barList, (item, index) => {
    return _openBlock(), _createElementBlock("li", {
      key: index,
      class: _normalizeClass($options.headIndex === index + 1 ? 'on' : ''),
      onClick: $event => $options.choseBar(index + 1)
    }, _toDisplayString(item.name), 11, _hoisted_3);
  }), 128))])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [$props.link ? (_openBlock(), _createBlock(_component_van_icon, {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.go(-1)),
    name: "arrow-left",
    size: "0.6rem"
  })) : _createCommentVNode("", true), _createElementVNode("h3", null, _toDisplayString($props.title), 1)])])]);
}