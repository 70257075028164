import "core-js/modules/es.array.push.js";
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    link: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  mounted() {
    let url = this.$route.fullPath;
    if (!url.includes("/projects") && !url.includes("project-details")) {
      this.$store.dispatch("setHeadIndex", 0);
    }
  },
  methods: {
    choseBar(value) {
      this.$store.dispatch("setHeadIndex", value);
      this.$router.push(`/projects?classid=${value}`);
    }
  },
  computed: {
    headIndex() {
      return this.$store.state.headIndex;
    },
    barList() {
      return this.$store.state.headItems;
    }
  }
};