import { getRecommend } from '@/api';
import QRCode from 'qrcode';
export default {
  data() {
    return {
      url: "",
      list: []
    };
  },
  mounted() {
    getRecommend().then(res => {
      this.generateQRCode(res.url);
      this.url = res.url;
      this.list = res.teamData;
    });
  },
  methods: {
    onCopySuccess() {
      this.$copyText(this.url).then(() => {
        this.$toast('复制成功！');
      }, () => {
        this.$toast('复制失败！');
      });
    },
    onCopyError() {
      this.$toast('复制失败！');
    },
    generateQRCode(data) {
      QRCode.toDataURL(data, {
        errorCorrectionLevel: 'H'
      }, (err, url) => {
        if (err) console.error(err);
        this.$refs.qrcode.innerHTML = '<img src="' + url + '">';
      });
    },
    onCopyMyId() {
      this.$copyText(this.userInfo.uid).then(() => {
        this.$toast('复制成功！');
      }, () => {
        this.$toast('复制失败！');
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};